<ng-template [ngxPermissionsOnly]="['accounts:can_get_accounts_reports_receivables']">
  <div class="box-row panel panel-default">
    <div class="panel-body text-center text-alpha">
      <div *ngIf="!isCountLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="isCountLoaded">
        <div class="text-lg mo">{{contactRevenue?.revenue | currency:contactRevenue.currency_code:contactRevenue.currency_precision}}</div>
        <p>Calls Revenue</p>
        <div class="mb-lg"></div>
      </ng-template>
    </div>
  </div>
</ng-template>