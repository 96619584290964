<fieldset class="fieldset-class" *ngFor="let day of daysArray; let j=index">
    <div class="form-group">
        <div class="col-md-6 col-md-offset-1 col-xs-12">
            <label class="switch">
            <input type="checkbox" [checked]="activationStatus[day]" name="day" (change)="changeActivationStatus(j, day)"/><span></span>
            </label> {{ day }}
        </div>
    </div>
    <div class="hyperlink-color col-md-offset-2" *ngIf="activeSchedules.indexOf(j)">
        <ng-template ngFor let-item [ngForOf]="model.segments" let-i="index">
            <div class="mb-lg" *ngIf="item.day == j">
                <div class="d-flex align-items-flex-end flex-wrap">
                    <div class="mr-lg mb-sm">
                        <label class=" control-label label-weight">From</label>
                        <select class="form-control" #startTime name="start_time" [(ngModel)]="model.segments[i].start_time">
                            <option *ngFor="let slot of startTimeSlotArray" [value]="slot">{{slot}}</option>
                        </select>
                    </div>

                    <div class="mr-lg mb-sm">
                        <label class="control-label label-weight">To</label>
                        <select class="form-control" #endTime name="end_time" [(ngModel)]="model.segments[i].end_time">
                            <option *ngFor="let slot of endTimeSlotArray" [value]="slot">{{slot}}</option>
                        </select>
                    </div>

                    <div class="mr-lg mb-sm" *ngIf="showRoutingNumberField">
                        <label class="control-label label-weight">Routing Number</label>
                        <input class="form-control" type="text" #routingNumber name="routing_number" placeholder="Enter Routing Number" [(ngModel)]="model.segments[i].routing_number"/>
                    </div>
                    <div class="mr-lg mb-sm" *ngIf="showPriceField">
                        <label class="control-label label-weight">Price</label>
                        <span class="input-group">
                            <span class="input-group-addon">{{currencyCode}}</span>
                            <input class="form-control" type="text" #price name="price" [(ngModel)]="model.segments[i].price"/>
                        </span>
                    </div>
                    <div class="mr-lg mb-sm d-flex align-items-center">
                        <a (click)="addDaySchedule(item.day)">Add&nbsp;|&nbsp;</a>
                        <div class="btn-group" dropdown>
                            <button type="button" class="btn btn-default btn-sm" dropdownToggle><b class="caret"></b>
                        </button>
                            <ul *dropdownMenu class="dropdown-menu add_days_schedule_dropdown" role="menu">
                            <li><a (click)="copyDown(i, j)">Copy Down</a></li>
                            <li><a (click)="removeDaySchedule(i)">Remove</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</fieldset>
