<ng-template [ngxPermissionsOnly]="['contacts:can_get_contacts_reports_new']">
  <div class="box-row panel panel-default">
    <div class="panel-body">
      <div *ngIf="!isContactLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="isContactLoaded">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Times Sold</th>
              <th>Revenue</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <ng-template ngFor let-contact [ngForOf]="contactsArray" let-i="index" [ngForTrackBy]="_trackByContactId">
              <tr>
                <td>
                  <a [routerLink]="[ '/leads',contact.id, 'detail']">{{contact.name}}</a>
                </td>
                <td>{{contact.sold}}</td>
                <td>{{contact.revenue | currency:contact.account.currency_code: contact.account.currency_precision}}</td>
                <td>
                  <ng-container *ngIf="contact.leadprice_id!=0">{{contact.leadprice.name}}</ng-container>
                  <ng-container *ngIf="contact.leadprice_id==0">No Price</ng-container>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </ng-template>
    </div>
  </div>
</ng-template>