import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { CallReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-call-revenue-widget',
  templateUrl: './call-revenue-widget.component.html',
  styleUrls: ['./call-revenue-widget.component.scss']
})

// @Privilege({
//   privilege: 'emailtemplates:can_get_emailtemplates_reports_sent'
// })

export class CallRevenueWidgetComponent implements OnInit {
  public isCountLoaded = false;
  public contactRevenue;
  constructor(
    private callReportService: CallReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService

  ) { }
  ngOnInit() {
    if (this.permissionService.hasPermission('accounts:can_get_accounts_reports_receivables')) {
      this.requestAccountReceiveable();
    }

  }

  requestAccountReceiveable() {
    this.isCountLoaded = false;
    this.callReportService
      .getRevenue()
      .subscribe((res: any) => {
        this.contactRevenue = res;
        this.isCountLoaded = true;
      });
  }

}
