import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { ToasterLibraryService, AccountReportService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';
import * as _ from 'lodash';

@Component({
  selector: 'app-accounts-new-bar-chart-widget',
  templateUrl: './accounts-new-bar-chart-widget.component.html',
  styleUrls: ['./accounts-new-bar-chart-widget.component.scss']
})

// @Privilege({
//   privilege: 'users:can_get_users_reports_sales'
// })
export class AccountsNewBarChartWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() widgetTitle: any = '';
  @Input() chartAspectRatio: any;

  public stackedBarChartData = [
    {
      data: [],
      label: this.widgetTitle,
      color: '#1fafff'
    }
  ];
  public number_of_occurrences;
  public stackedBarChartLoaded = false;
  constructor(
    private accountReportService: AccountReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('users:can_get_users_reports_sales')) {
      this.getStackedBarChartData();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('users:can_get_users_reports_sales')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getStackedBarChartData();
      }
    }
  }
  getStackedBarChartData() {
    const criteriaArray = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      }
    ];

    this.stackedBarChartLoaded = false;
    this.reInit();
    this.accountReportService.getClientChart(criteriaArray).subscribe(
      (res: any) => {
        Logger().info('accounts-new-bar-chart', 'res.data', res.data);
        if (res.success === 'true') {
          const forAggregation = res.data.slice(0);
          const afterAggregation: any = [];
          // Aggregate the data so we don't have duplicate bars for each category.
          forAggregation.forEach((record, index, theArray) => {
            if (undefined !== afterAggregation && afterAggregation.length > 0) {
              const idx = afterAggregation.findIndex(item => item.category === record.category);
              if (idx !== -1) {
                afterAggregation[idx].number_of_occurrences =
                  parseInt(afterAggregation[idx].number_of_occurrences, 10) +
                  parseInt(record.number_of_occurrences, 10);
              } else {
                afterAggregation.push(record);
              }
            } else {
              afterAggregation.push(record);
            }
          });
          // Sort by total visits
          const byTotalVisits = afterAggregation.slice(0);
          // byTotalVisits.sort(function(a, b) {
          //   return b.number_of_occurrences - a.number_of_occurrences;
          // });

          byTotalVisits.forEach(record => {
            if (record.category) {
              this.stackedBarChartData[0].data.push([record.category, parseInt(record.number_of_occurrences, 10)]);
            }
          });
          Logger().info('accounts-new-bar-chart', 'this.stackedBarChartData', this.stackedBarChartData);
        }
        this.stackedBarChartLoaded = true;
      },
      err => {
        const errorMessage = err?.error?.message || "An unexpected error occurred.";
        this.toasterLibrary.error(null, errorMessage);
      }
    );
  }
  private reInit() {
    this.stackedBarChartData = [
      {
        data: [],
        label: this.widgetTitle,
        color: '#1fafff'
      }
    ];
  }
}
