<ng-template [ngxPermissionsOnly]="['visitors:can_get_visitors_reports_average_conversion_rate']">
  <div class="box-row panel panel-default">
    <div class="panel-body text-center text-alpha">
      <div *ngIf="!isCpaLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="isCpaLoaded">
        <div class="text-lg mo">{{costPerAcquisition.average_cpa | currency:costPerAcquisition.currency_code: costPerAcquisition.currency_precision}}</div>
        <p>Avg Cpa</p>
        <div class="mb-lg"></div>
      </ng-template>
    </div>
  </div>
</ng-template>
